import React, { Component } from "react";
import { Link } from "react-router-dom";
import Session from "../../Services/Session";
import Api from "../../Services/Api";

import NavBarTop from "../../Layout/Components/NavBar";
import { Alert, Container, Spinner, Table, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";

export default class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                token: "",
                email: "",
                avatar: "",
                role_id: "",
                name: "",
        },
            loading: true,
            userArray: [],
        };
        this.getUser = this.getUser.bind(this);
        this.getAllUsers = this.getAllUsers.bind(this);
        this.fillUserTable = this.fillUserTable.bind(this);
    }
    async componentDidMount() {
        await this.getUser();

        if (this.state.user.role_id === "1") {
            await this.getAllUsers();
        }
        this.setState({
            loading: false,
        });
    }

    async getUser() {
        let user = Session.getInfoUser();
        this.setState({
            user
        });
    }

    async getAllUsers(token) {
        await Api.getAllUsers({token}).then((response) => {
            this.setState({userArray: response})
        });
    }

    fillUserTable = (userArray) => {
      return (userArray.map(function (userElement) {
        return (
          <tr key={userElement.id}>
            <Tooltip
              arrow
              disableFocusListener
              disableTouchListener
              placement="left"
              title={userElement.breal_id}
            >
              <td>{userElement.id}</td>
            </Tooltip>
            <td>{userElement.nombre}</td>
            <td>{userElement.email}</td>
            <td>{userElement.activo === 1 ? "Si" : "No"}</td>
            <td>
              <Link to={"/user_profile/" + userElement.id}>
              <Button
                size="sm"
              >
                <FontAwesomeIcon
                  icon={faEye}
                ></FontAwesomeIcon>
              </Button>
              </Link>
              <Button
                variant="danger"
                size="sm"
                onClick={(e) => { Api.deactivateUser({ user_id: userElement.id }) }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                ></FontAwesomeIcon>
              </Button>

            </td>
          </tr>
        );
      }))
    }

    hideAlert = () => {
        this.setState({ alert: false });
    };

    showAlert(text, variant) {
        const getAlert = () => (
            <Alert
                variant={variant}
                onClose={() => this.hideAlert(false)}
                dismissible
            >
                <p>{text}</p>
            </Alert>
        );

        this.setState({
            alert: getAlert(),
        });
    }
    
    render() {
        const { alert } = this.state;
        return this.state.loading ? (
            <>
                <NavBarTop />
                <Container className="rectangle">
                    <Spinner animation="grow" variant="dark" />
                </Container>
            </>
        ) : (
            <>
                <NavBarTop />
                <div className="layout-main">
                <Row>
                    <Col></Col>
                    <div
                        className="col-12 col-md-6"
                        onClick={this.hideAlert}
                    >
                        {alert}
                    </div>
                    <Col></Col>
                </Row>
                <Container className="rectangle-transparent">
                        <Row>
                            <Link to="/admin"><h6 className="h6-margin">Administrar usuarios</h6></Link>
                        </Row>
                    </Container>
                    <Container className="rectangle">
                        {" "}
                        {this.state.user.role_id === "1" ? <h3>Administración de usuarios</h3> : "Qué haces aqui?"}
                    </Container>
                    <Container className="rectangle">
                        <Table responsive hover size="sm">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Vigente</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.fillUserTable(this.state.userArray)}
                            </tbody>
                        </Table>
                    </Container>
                </div>
            </>
        );
    }
}
