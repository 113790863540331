import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./index.css";
import "./Css/main.scss";
import * as serviceWorker from "./serviceWorker";
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import Home from "./Components/Home/Home";
import SignIn from "./Auth/SignIn";
import LogOut from "./Auth/LogOut";
import Admin from "./Components/Home/Admin";
import Property from "./Components/Home/PropertyInfo";
import PasswordRecovery from "./Auth/PasswordRecovery";
import Profile from './Components/Home/Profile';


function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={SignIn} />
                <Route exact path="/logout" component={LogOut} />
                <PrivateRoute path="/admin" component={Admin} />
                <PrivateRoute path="/home" component={Home} />
                <PrivateRoute path="/property/:id" component={Property} />
                <PrivateRoute path="/user_profile/:id" component={Profile} />
                <Route path="/recovery_password" component={PasswordRecovery} />
            </Switch>
        </Router>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
