import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {Button, ButtonGroup, Modal, InputGroup, FormControl, Alert, Row, Col, Container, Form} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faDownload, faTrash} from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert'
import Api from '../../Services/Api'
import moment from 'moment'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}))

const formatDate = value => {
  const [fullDate, time] = value.split('T')
  const [years, months, date] = fullDate.split('-')
  const [hours, minutes, seconds] = time.split(':')
  return moment.utc({ years, months: (months - 1), date, hours, minutes, seconds}).local().format('DD-MM-YYYY HH:mm A')
}

const ModalMailsList = (props) => {
  const [mail, setMail] = useState('')
  const [recipientNameMail, setRecipientNameMail] = useState('')
  const [loading, setLoading] = useState(false)
  const [sendMailStatus, setSendMailStatus] = useState(null)

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Envio de e-mail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-5 pb-5">
        <Container>
          <Row>
            <Col>
              <InputGroup size="md">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    Nombre destinatario
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Usuario"
                  value={recipientNameMail}
                  onChange={event => setRecipientNameMail(event.target.value)}/>
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="md">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    E-mail
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="usuario@mail.com"
                  value={mail}
                  onChange={event => setMail(event.target.value)}/>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        {
          sendMailStatus && (
            <Alert style={{marginTop: 20}} variant={sendMailStatus} dismissible onClose={() => {
              setRecipientNameMail('')
              setMail('')
              setSendMailStatus('')
            }}>
              {sendMailStatus === 'success' ? (
                <p className={'m-0'}>El e-mail a sido enviado correctamente.</p>
              ) : null}
              {sendMailStatus === 'danger' ? (
                <p className={'m-0'}>Ha ocurrido un error con el envio.</p>
              ) : null}
            </Alert>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={loading}
          onClick={!loading ? () => {
            console.log('mail', mail)
            setLoading(true)
            sendMail(mail, props.file.s3_path, recipientNameMail).then(() => {
              setLoading(false)
              setSendMailStatus('success')
            }).catch(() => {
              setLoading(false)
              setSendMailStatus('danger')
            })
          } : null}>
          {loading ? 'Enviando...' : 'Enviar'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const sendMail = (to, fileURL, recipientName) => {
  return Api.sendFileForMail({
    to,
    fileURL,
    recipientName
  })
}

const createTable = (fileArray, fileType, deleteFile, modalMailsListShow, setModalMailsListShow, fileInModal, setFileInModal) => {
  return (
    <>
      <ModalMailsList
        show={modalMailsListShow}
        onHide={() => setModalMailsListShow(false)}
        file={fileInModal}
      />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className="bold-text">
            <TableRow>
              <TableCell>Fecha de subida</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileArray.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {formatDate(row.created_at)}
                </TableCell>
                <TableCell style={{width: 150}}>
                  <ButtonGroup style={{float: 'right'}}>
                    {row.tipo_id === 4 &&
                    <Button variant="outline-primary" onClick={() => {
                      setFileInModal(row)
                      setModalMailsListShow(true)
                    }}>
                      <FontAwesomeIcon icon={faEnvelope}/>
                    </Button>
                    }
                    <Button href={row.s3_path} download={fileType + '_' + row.created_at}>
                      <FontAwesomeIcon icon={faDownload}/>
                    </Button>
                    <Button variant='danger' onClick={() => deleteFile(row)}>
                      <FontAwesomeIcon icon={faTrash}/>
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const ScrollableTabsButtonAuto = (props) => {
  useEffect(() => {
    setContratoGestion(props.files.contratoGestion)
    setContratoArriendo(props.files.contratoArriendo)
    setReparaciones(props.files.reparaciones)
    setFacturas(props.files.facturas)
    setVarios(props.files.varios)
  }, [props])

  const classes = useStyles()
  const [modalMailsListShow, setModalMailsListShow] = React.useState(false)
  const [fileInModal, setFileInModal] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const [contratoGestion, setContratoGestion] = React.useState(props.files.contratoGestion)
  const [contratoArriendo, setContratoArriendo] = React.useState(props.files.contratoArriendo)
  const [reparaciones, setReparaciones] = React.useState(props.files.reparaciones)
  const [facturas, setFacturas] = React.useState(props.files.facturas)
  const [varios, setVarios] = React.useState(props.files.varios)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const deleteFile = (file) => {
    swal({
      title: `¿Está seguro que desea eliminar el archivo?`,
      text: 'Una vez eliminado, podría no volver a ser recuperado',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true
        },
        confirm: {
          text: 'Si, eliminar',
          value: true,
          visible: true,
          className: '',
          closeModal: false
        }
      },
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          return Api.deleteFile(file.id)
        } else {
          throw new Error('close')
        }
      }).then(({error}) => {
      if (!error) {
        swal('Archivo eliminado con éxito', {
          icon: 'success'
        })
        if (file.tipo_id === 1) setContratoGestion(contratoGestion.filter(item => item.id !== file.id))
        if (file.tipo_id === 2) setContratoArriendo(contratoArriendo.filter(item => item.id !== file.id))
        if (file.tipo_id === 3) setReparaciones(reparaciones.filter(item => item.id !== file.id))
        if (file.tipo_id === 4) setFacturas(facturas.filter(item => item.id !== file.id))
        if (file.tipo_id === 5) setVarios(varios.filter(item => item.id !== file.id))
      } else {
        throw new Error('Api error')
      }
    }).catch(err => {
      if (err.message === 'close') {
        swal.stopLoading()
        swal.close()
      } else if (err) {
        swal('Error', 'La acción solicitada no se ha completado', 'error')
      } else {
        swal.stopLoading()
        swal.close()
      }
    })
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Contrato de gestion" {...a11yProps(0)} />
          <Tab label="Contrato de arriendo" {...a11yProps(1)} />
          <Tab label="Reparaciones y Post Venta" {...a11yProps(2)} />
          <Tab label="Liquidaciones" {...a11yProps(3)} />
          <Tab label="Varios" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <br/>
        {
          createTable(
            contratoGestion,
            'contrato_gestion',
            deleteFile,
            modalMailsListShow,
            setModalMailsListShow,
            fileInModal,
            fileInModal,
            setFileInModal)
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        {
          createTable(
            contratoArriendo,
            'contrato_arriendo',
            deleteFile,
            modalMailsListShow,
            setModalMailsListShow,
            fileInModal,
            setFileInModal
          )
        }
      </TabPanel>
      <TabPanel value={value} index={2}>
        {
          createTable(
            reparaciones,
            'reparacion',
            deleteFile,
            modalMailsListShow,
            setModalMailsListShow,
            fileInModal,
            setFileInModal
          )
        }
      </TabPanel>
      <TabPanel value={value} index={3}>
        {
          createTable(
            facturas,
            'factura',
            deleteFile,
            modalMailsListShow,
            setModalMailsListShow,
            fileInModal,
            setFileInModal
          )
        }
      </TabPanel>
      <TabPanel value={value} index={4}>
        {
          createTable(
            varios,
            'varios',
            deleteFile,
            modalMailsListShow,
            setModalMailsListShow,
            fileInModal,
            setFileInModal
          )
        }
      </TabPanel>
    </div>
  )
}

export default ScrollableTabsButtonAuto
