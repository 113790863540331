import React, { Component } from "react";
import { Alert, Container, Form, Button } from "react-bootstrap";
import Api from "../Services/Api";
import NavBarTop from "../Layout/Components/NavBar";
import { Row, Col } from "react-bootstrap";

export default class PasswordRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        email: "",
        password: "",
      },
      defaultInput: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true })
    const { data } = this.state;
    await Api.changePass(data).then((response) => {
      if (response.success) {
        this.showAlert("Contraseña cambiada con éxtio!", "success");
      } else {
        this.showAlert("Error en cambio de contraseña, por favor intentar más tarde...", "danger");
      }
    })
  }

  onChange = (event) => {
    const { data } = this.state;
    const { name, value } = event.target;
    data[name] = value;
    this.setState({ data });
  };

  hideAlert = () => {
    this.setState({ alert: false });
  };

  showAlert(text, variant) {
    const getAlert = () => (
      <Alert
        variant={variant}
        onClose={() => this.hideAlert(false)}
        dismissible
      >
        <p>{text}</p>
      </Alert>
    );

    this.setState({
      alert: getAlert(),
    });
  }

  render() {
    const { alert } = this.state;
    return (
      <>
        <NavBarTop />
        <div className="layout-main">
          <Container className="rectangle">
            <Row>
              <Col></Col>
              <div
                className="col-12 col-md-6"
                onClick={this.hideAlert}
              >
                {alert}
              </div>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col md="auto" sm lg>
                <Form id="SignInForm">
                  <Form.Group controlId="SignInForm">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="* * * * * *"
                      onChange={this.onChange}
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    variant="dark"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Cambiar Contraseña
                  </Button>
                </Form>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
