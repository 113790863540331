import React, { Component } from "react";
import { Link } from "react-router-dom";
import Session from "../../Services/Session";
import Api from "../../Services/Api";

import NavBarTop from "../../Layout/Components/NavBar";
import { Alert, Container, Spinner, Row, Col, Button, ButtonGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSave, faUpload, faDownload } from "@fortawesome/free-solid-svg-icons";
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default class Profile extends Component {
  constructor (props) {
    super(props);
    this.state = {
      user: {
        token: "",
        email: "",
        avatar: "",
        role_id: "",
        name: "",
      },
      data: {
        document: ""
      },
      loading: true,
      alert: "",
      user_info: {},
      new_password: "",
      files : []
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    document.title = "The Brokers - Intranet";
    let user = Session.getInfoUser();
    this.setState({ user });
    var response = await Api.getUserProfile({user_id: this.props.match.params.id});
    this.setState({
      loading: false,
      user_info: response.user_info[0],
      files: response.files
    })
  }

  async onSubmit(e) {
        e.preventDefault();
        let { data } = this.state;
        const request = new FormData();
        request.append('document', data.document);

        let response = await Api.uploadFile(request);
        let params = {
          documentUrl: response.data.documentUrl,
          user_id: this.props.match.params.id
        };
        let insertResponse = await Api.insertUserFile(params);
        this.showAlert(insertResponse.message, 'success');

    }

  onChange = (e) => {
      let { data } = this.state;
      data.document = e.target.files[0];
  };

  onPasswordChange = (e) => {
    this.setState({
      new_password: e.target.value
    });
  }

  onPasswordSubmit = async () => {
    const passwordChangeResponse = await Api.changePass({
      email: this.state.user_info.email,
      password: this.state.new_password
    })
    this.showAlert(passwordChangeResponse.message, 'success');
  }

  sendFiles = async (filePath, userEmail) => {
    const params = {
      user_email: userEmail,
      file_path: filePath
    }
    const response = Api.sendFilesToUser(params);
  }

  hideAlert = () => {
      this.setState({ alert: false });
    };

  showAlert(text, variant) {
    const getAlert = () => (
      <Alert
        variant={variant}
        onClose={() => this.hideAlert(false)}
        dismissible
      >
        <p>{text}</p>
      </Alert>
    );

    this.setState({
      alert: getAlert(),
    });
  }

  formatDate = (value) => {
    const [date, time] = value.split('T');
    let [year, month, day] = date.split('-');
    let [hh, mm, ss] = time.split(':');
    return `${day}-${month}-${year} ${hh}:${mm} hrs`;
  }

  render() {
    let { alert } = this.state;
    return this.state.loading ? (
        <>
          <NavBarTop />
          <Container className="rectangle">
            <Spinner animation="grow" variant="dark" />
          </Container>
        </>
      ) : (
        <>
          <NavBarTop />
          <div className="layout-main">
            <Container className="rectangle-transparent">
              <Row>
                <Link to="/admin">
                  <h6 className="h6-margin">Administrar usuarios</h6>
                </Link>
              </Row>
            </Container>
          <Container className="rectangle">
              <h3>{this.state.user_info.nombre}</h3>
              <h6>{this.state.user_info.email}</h6>
          </Container>
            <Container className="rectangle-transparent">
              <Row>
                <Col></Col>
                <div
                  className="col-12 col-md-6"
                  onClick={this.hideAlert}
                  >
                  {alert}
                </div>
                <Col></Col>
              </Row>
            </Container>
          <Container className="rectangle">
            <Row>
              <Col>
                  <h4>Cambiar contraseña</h4>
                  <Row>
                    <Col>
                      <TextField
                        name="password"
                        placeholder="* * * *"
                        type="password"
                        label="Nueva contraseña"
                        variant="outlined"
                        onChange={this.onPasswordChange}
                      ></TextField>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col>
                      <Button variant="outline-primary" onClick={this.onPasswordSubmit}>
                        <FontAwesomeIcon icon={faSave} /> Cambiar contraseña
                      </Button>
                    </Col>
                  </Row>
              </Col>
              { this.state.user.role_id === "1" ? (
                <Col>
                    <h4>Subir Liquidación</h4>
                    <InputBase
                      name="document"
                      placeholder="Seleccione un archivo"
                      type="file"
                      onChange={this.onChange}></InputBase>
                    <br />
                    <br />
                    <ButtonGroup>
                      <Button
                        variant="outline-primary"
                        type="submit"
                        onClick={this.onSubmit}>
                        <FontAwesomeIcon icon={faUpload} /> Subir Archivo
                    </Button>
                    </ButtonGroup>
                  </Col>
              ) : (
                <Col></Col>
              ) }
            </Row>
          </Container>
          <Container className="rectangle">
              <h4>Liquidaciones anteriores</h4>
              <Row>
                <Col>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead className="bold-text">
                      <TableRow>
                        <TableCell>Fecha de subida</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { this.state.files.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            { this.formatDate(row.created_at)}
                          </TableCell>
                          <TableCell>
                            <ButtonGroup>
                              <Button
                                variant="outline-primary"
                                onClick={() => this.sendFiles(row.s3_path, this.state.user_info.email)}>
                                <FontAwesomeIcon icon={faEnvelope} />
                                </Button>
                              <Button href={row.s3_path} download={"liquidacion_" + row.created_at}>
                                <FontAwesomeIcon icon={faDownload} />
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      )) }
                    </TableBody>
                  </Table>
                </TableContainer>
                </Col>
              </Row>

          </Container>
          </div>
        </>
      )
  }
}
