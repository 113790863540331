var API = null;
// TODO: Change this with production API
if (process.env.NODE_ENV === "production") {
    // API = "http://api.thebrokers.cl"
    API = "http://18.230.147.69:1000"
    //API = "http://localhost:1000"
} else {
    // API = "http://api.thebrokers.cl"
    API = "http://localhost:1000"
    // API = "http://18.230.147.69:1000"
}
export const BACK_TOKEN = "";
export const BACK_URL = API;
