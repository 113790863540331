import React, { Component } from "react";
import { Link } from "react-router-dom";
import Session from "../../Services/Session";
import Api from "../../Services/Api";
import Tooltip from "@material-ui/core/Tooltip";
import NavBarTop from "../../Layout/Components/NavBar";
import {
    Container,
    Spinner,
    Table,
    Col,
    Row,
    Button,
    Badge,
} from "react-bootstrap";
import DefaultAvatar from "../../Layout/Assets/user.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import swal from 'sweetalert'

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                token: "",
                email: "",
                avatar: "",
                role_id: "",
                name: "",
            },
            loading: true,
            propertiesArray: [],
            avg: 0.0,
            selectedProperty: "",
        };
        this.getUser = this.getUser.bind(this);
        this.getUserProperties = this.getUserProperties.bind(this);
        this.getAllProperties = this.getAllProperties.bind(this);
        this.getAverage = this.getAverage.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.fillHomeBody = this.fillHomeBody.bind(this)
    }
    async componentDidMount() {
        document.title = "The Brokers - Home";
        let user = await this.getUser();
        if (user.role_id === "1") {
            await this.getAllProperties({roler_id: user.role_id});
        } else await this.getUserProperties(user.email);
        await this.getAverage();
        this.setState({
            loading: false,
        });
    }

    async getUser() {
        let user = Session.getInfoUser();
        this.setState({ user });
        return user;
    }

    async getUserProperties(email) {
        const propertiesArray = await Api.getProperties({ email: email });
        this.setState({ propertiesArray: propertiesArray });
    }

    async deleteProperty(id) {
      return Api.deleteProperty(id);
    }

    async getAllProperties(role_id) {
        const propertiesArray = await Api.getAllProperties({role_id});
        const uniquePropertiesArray = propertiesArray.reduce((items, item) => items.filter(i => i.id === item.id).length < 1 ? [...items, item] : items, [])
        this.setState({propertiesArray: uniquePropertiesArray})
    }

    async getAverage() {
        let avg = await Api.getAverage();
        let stateAvg = 0;
        let lenght = 0;
        avg.map((value) => {
          stateAvg += value.valor;
          lenght += 1;
        });
        stateAvg /= lenght;
        this.setState({ avg: stateAvg });
    }

    deleteItem = (item) => {
        swal({
            title: `¿Está seguro que desea eliminar el proyecto ${item.condominio} ${item.nro_depto === 'null'  ? 'S/N' : 'depto ' + item.nro_depto}?`,
            text: "Una vez eliminado, podría no volver a recuperar el proyecto",
            icon: "warning",
            buttons: {
              cancel: {
                text: "Cancel",
                visible: true,
                closeModal: true,
              },
              confirm: {
                text: "Si, eliminar",
                value: true,
                visible: true,
                className: "",
                closeModal: false
              }
            },
            dangerMode: true,
        })
          .then((willDelete) => {
              if (willDelete) {
                return this.deleteProperty(item.id)
              } else {
                  throw new Error('close');
              }
          }).then(({error}) => {
            if (!error) {
              swal("Proyecto eliminado con éxito", {
                icon: "success",
              })
                this.setState({ propertiesArray: this.state.propertiesArray.filter(_item => _item.breal_id !== item.breal_id) });
            } else {
                throw new Error('Api error');
            }
        }).catch(err => {
            if (err.message === 'close') {
                swal.stopLoading();
                swal.close();
            } else if (err) {
                swal("Error", "La acción solicitada no se ha completado", "error");
            }
            else {
                swal.stopLoading();
                swal.close();
            }
        });
    }

    fillHomeBody = (propertiesArray) => {
        return (propertiesArray.map((propertyElement) => {
            return (
                <tr
                    key={
                        propertyElement.breal_id +
                        "-" +
                        propertyElement.id
                    }
                >
                    <td>
                      {propertyElement.id}
                    </td>
                    <td>
                        {propertyElement.condominio}
                    </td>
                    <td>
                        { propertyElement.client_nombre }
                    </td>
                    <Tooltip
                        arrow
                        disableFocusListener
                        disableTouchListener
                        placement="left"
                        title={returnToolTipText(
                            propertyElement.rol_bodega
                        )}
                    >
                        <td>
                            {returnTextByNullField(
                                propertyElement.nro_bodega,
                                "B"
                            )}
                        </td>
                    </Tooltip>
                    <Tooltip
                        arrow
                        disableFocusListener
                        disableTouchListener
                        placement="left"
                        title={returnToolTipText(
                            propertyElement.rol_estacionamiento
                        )}
                    >
                        <td>
                            {returnTextByNullField(
                                propertyElement.nro_estacionamiento,
                                "E"
                            )}
                        </td>
                    </Tooltip>
                    <Tooltip
                        arrow
                        disableFocusListener
                        disableTouchListener
                        placement="left"
                        title={returnToolTipText(
                            propertyElement.rol_depto
                        )}
                    >
                        <td>
                            {
                                propertyElement.nro_depto === "null"? "S/D" : propertyElement.nro_depto
                            }
                        </td>
                    </Tooltip>
                    <td>
                        {returnBadgeByState(
                            propertyElement.arriendo
                        )}
                    </td>
                    <td>
                        {"$ " +
                            new Intl.NumberFormat(
                                "de-DE",
                                {
                                    style:
                                        "currency",
                                    currency: "CLP",
                                }
                            ).format(
                                propertyElement.valor_arriendo
                            )}
                    </td>
                    <th width={120}>
                        <div className="d-flex justify-content-around px-2">
                            <Link to={"/property/" + propertyElement.id }>
                                <Button size="sm">
                                    <FontAwesomeIcon icon={faEye}/>
                                </Button>
                            </Link>
                            <Button size="sm" variant='danger' onClick={() => this.deleteItem(propertyElement)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </Button>
                        </div>
                    </th>
                </tr>
            );
        }))
    }

    handleClick = (e, id) => {
        Session.setPropertyId(id);
    };

    render() {
        let { propertiesArray } = this.state;
        return this.state.loading ? (
            <>
                <NavBarTop />
                <Container className="rectangle">
                    <Spinner animation="grow" variant="dark" />
                </Container>
            </>
        ) : (
            <>
                <NavBarTop />
                <div className="layout-main">
                    <Container className="rectangle-transparent">
                        <Row>
                            <Link to="/home">
                                <h6 className="h6-margin">Inicio</h6>
                            </Link>
                        </Row>
                    </Container>
                    <Container className="rectangle">
                        <Row>
                            <Col>
                                <img
                                    className="img-fluid"
                                    src={DefaultAvatar}
                                    alt="avatar"
                                />
                            </Col>
                            <Col>
                                <h4>{this.state.user.name}</h4>
                                <h6>Correo: {this.state.user.email}</h6>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
                <div className="layout-main-darker">
                    <Container className="rectangle margin-top-10">
                        <h3>Mis propiedades</h3>
                        <Row>
                            <Col md>
                                <p>
                                    Total:{" "}
                                    <Badge variant="secondary">
                                        {this.state.propertiesArray.length}
                                    </Badge>
                                </p>
                            </Col>
                            <Col md>
                                {this.state.avg > 0.0 ? (
                                    <p>
                                        Promedio arriendo:{" "}
                                        <Badge variant="secondary">
                                            {"$" +
                                                new Intl.NumberFormat("de-DE", {
                                                    style: "currency",
                                                    currency: "CLP",
                                                }).format(this.state.avg)}
                                        </Badge>
                                    </p>
                                ) : (
                                    <p>
                                        Promedio:{" "}
                                        <Badge variant="secondary">0</Badge>
                                    </p>
                                )}
                            </Col>
                            <Col md></Col>
                        </Row>
                        <Row>
                            <Table responsive hover size="sm">
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Proyecto</th>
                                        <th>Cliente</th>
                                        <th>Bodega</th>
                                        <th>Estacionamiento</th>
                                        <th>Depto</th>
                                        <th>Estado</th>
                                        <th width={130}>Precio</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {propertiesArray.error ? (
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        ) : (this.fillHomeBody(propertiesArray))}
                                </tbody>
                            </Table>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

function returnBadgeByState(text) {
    if (text === "Disponible") {
        return <Badge variant="primary">{text}</Badge>;
    } else {
        return <Badge variant="success">{text}</Badge>;
    }
}

// Text: Text from SQL, Type: Estacionamiento, Bodega, etc...
function returnTextByNullField(text, type) {
    var output = "";
    if (text === "null" || text === "undefined" || text === "pte") {
        output = type === "B" ? "S/B" : "S/E";
    } else output = text;
    return output;
}

function returnToolTipText(text) {
    if (text === "null" || text === "S/E" || text === "S/B" || text === "pte") return "Sin Rol";
    else return text;
}

