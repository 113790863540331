import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import Logo from "../Assets/favicon.svg";
import Session from "../../Services/Session";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/*TODO: When profile is available add -> faUserTie*/
import { faSignOutAlt, faUsersCog, faHome, faUserTie } from "@fortawesome/free-solid-svg-icons";


export default class NavBarTop extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            logo: Logo,
            isOpen: false,
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }
    render() {
        return (
            <Navbar
                className="nav-bar-top"
                sticky="top"
                expand="sm"
                variant="dark"
            >
                <Navbar.Brand href="/home">
                    <img alt="The Brokers" className="img-fluid" src={Logo}/>
                </Navbar.Brand>

                {Session.isAuth() ? (
                    <Nav className="ml-auto">
                            <Nav.Link href="/home">
                                <FontAwesomeIcon icon={faHome} className="img-radius"/> Propiedades
                            </Nav.Link>
                            <Nav.Link href={"/user_profile/" + Session.getUserId()}>
                              <FontAwesomeIcon icon={faUserTie} className="img-radius" /> Mi Perfil
                            </Nav.Link>
                        {Session.getUserRole() === "1" ? (
                            <Nav.Link href="/admin">
                                <FontAwesomeIcon icon={faUsersCog} className="img-radius"/> Usuarios
                            </Nav.Link> ) : (
                              <></>
                            )}
                        <Nav.Link href="/logout">
                            <FontAwesomeIcon icon={faSignOutAlt} className="img-radius"/> Cerrar Sesión
                        </Nav.Link>
                    </Nav>
                ) :(
                <h6>{" "}</h6>
                ) }          
            </Navbar>
        );
    }
}