import React, { Component } from "react";
import { Alert, Container, Form, Button, Spinner } from "react-bootstrap";

import Api from "../Services/Api";
import Session from "../Services/Session";

import NavBarTop from "../Layout/Components/NavBar";
import { Row, Col } from "react-bootstrap";

export default class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                email: "",
                password: "",
            },
            defaultInput: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({loading: true})
        const { data } = this.state;
        await Api.signIn(data).then((response) => {
            if (
                response.code === 400 || response.code === 500
            ) {
                this.showAlert(response.response);
                e.value = "";
                this.setState({
                    data: {
                        email: "",
                        password: "",
                        property_id:"",
                    },
                });
            } else if (response.code === 200) {
                Session.open(
                    response.token,
                    response.email,
                    response.avatar,
                    response.role_id,
                    response.name,
                    response.id
                );
                window.location.href = "/home";
            }
        });
    }

    onChange = (event) => {
        const { data } = this.state;
        const { name, value } = event.target;
        data[name] = value;
        this.setState({ data });
    };

    hideAlert = () => {
        this.setState({ alert: false });
    };

    showAlert(text) {
        const getAlert = () => (
            <Alert
                variant="danger"
                onClose={() => this.hideAlert(false)}
                dismissible
            >
                <p>{text}</p>
            </Alert>
        );

        this.setState({
            alert: getAlert(),
            loading: false
        });
    }

    render() {
        const { alert } = this.state;
        return (
            <>
                <NavBarTop />
                <div className="layout-main">
                    <Container className="rectangle">
                        <Row>
                            <Col></Col>
                            <div
                                className="col-12 col-md-6"
                                onClick={this.hideAlert}
                            >
                                {alert}
                            </div>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col md="auto" sm lg>
                                <Form id="SignInForm">
                                    <Form.Group controlId="SignInForm">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Correo"
                                            onChange={this.onChange}
                                        ></Form.Control>

                                        <Form.Label>Contraseña</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="* * * *"
                                            onChange={this.onChange}
                                        ></Form.Control>
                                    </Form.Group>
                                    {this.state.loading ? (
                                      <Button
                                        variant="dark"
                                        type="submit"
                                        disabled
                                      >
                                        <Spinner
                                          as="span"
                                          animation="grow"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                        Cargando...
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="dark"
                                        type="submit"
                                        onClick={this.handleSubmit}
                                      >
                                        Iniciar Sesión
                                      </Button>
                                    )}
                                </Form>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
