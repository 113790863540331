import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Session from './../../Services/Session';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            Session.isAuth()
                ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
        }
    />
);

export default PrivateRoute;