class Session {
    constructor(props) {
        this.state = {
            token: localStorage.getItem("token"),
            email: localStorage.getItem("email"),
            avatar: localStorage.getItem("avatar"),
            role_id: localStorage.getItem("role_id"),
            name: localStorage.getItem("name"),
            id: localStorage.getItem("id"),
        };
    }
    open = (token, email, avatar, role_id, name, id) => {
        localStorage.setItem("token", token);
        localStorage.setItem("email", email);
        localStorage.setItem("avatar", avatar);
        localStorage.setItem("role_id", role_id);
        localStorage.setItem("name", name);
        localStorage.setItem("id", id);
      };

    isAuth = () => {
        let isAuth = "token" in localStorage;
        return isAuth;
    };

    getTokenUser = () => {
        return this.state.token;
    };

    getStatusUser = () => {
        return this.state.scope;
    };
    getInfoUser = () => {
        return this.state;
    };

    getUserRole = () => {
        return this.state.role_id;
    };

    getUserId = () => {
      return this.state.id;
    }

    close = () => {
        localStorage.clear();
    };
}
export default new Session();
