import Fetch from './Fetch'
import axios from 'axios'
import {BACK_URL} from '../config'
import Session from './Session'

class Api {
    async signIn(params) {
        let response = await Fetch.post("/login", params);
        if (response) {
            return response;
        }
    }

    async logOut(params) {
        let response = await Fetch.post("/logout", params);
        if (response) return response;
    }

    async checkAuth(params) {
        let response = await Fetch.get("/token", params);
        if (response) {
            return response;
        }
    }

    async resetEmailPass(params) {
        let response = await Fetch.post("/recovery_password", params);
        if (response) {
            return response;
        }
    }

    async changePass(params) {
        let response = await Fetch.patch("/password", params);
        if (response) {
            return response;
        }
    }

    async listRoles() {
        return await Fetch.get("/role");
    }

    async getAllUsers() {
        return await Fetch.get("/users");
    }

    async getUserProfile(params) {
      let user_info = await Fetch.get("/user_profile", params);
      let files = await Fetch.get("/user_document", params);
      return {user_info, files};
    }

    async deactivateUser(params) {
        return await Fetch.post("/users/deactivate", params);
    }

    async getAllProperties(params) {
        return await Fetch.get("/properties", params);
    }

    async getProperties(params) {
        return await Fetch.get("/client/properties", params);
    }

    async deleteProperty(id) {
        return await Fetch.delete(`/property/${id}`);
    }

    async getSingleProperty(params) {
        return await Fetch.get("/property", params);
    }

    async getPropertyRents(params) {
        return await Fetch.get("/property/rents", params);
    }

    async uploadFile(params) {
      let response =  await axios.post(BACK_URL + "/uploadFileS3", params, {
        headers: {
          authorization: Session.getTokenUser(),
        }
      });
      if (response) {
        return response;
      }
    }

  async deleteFile(id) {
    return await axios.delete(BACK_URL + "/document/" + id, {
      headers: {
        authorization: Session.getTokenUser(),
      }
    });
  }

    async insertFile(params) {
      let response = await Fetch.post("/document", params);
      if (response) {
        return response;
      }
    }

    async insertUserFile(params) {
      let response = await Fetch.post("/user_document", params);
      if (response) {
        return response;
      }
    }

    async getPropertyFiles(params) {
        let response = Fetch.get("/document", params);
        if (response) {
            return response;
        }
    }

    async getAverage(params) {
        let response = Fetch.get("/average", params);
        if (response) {
            return response;
        }
    }

    async sendFilesToUser(params) {
        let response = Fetch.post("/send/file/user", params);
        if (response) return response;
    }

    sendFileForMail(params) {
      return axios.post(BACK_URL + "/send/file",  params, {
        headers: {
          authorization: Session.getTokenUser(),
        }
      });
    }
}

export default new Api();
