import React, {Component} from 'react'
import Session from '../../Services/Session'
import Api from '../../Services/Api'
import {Link} from 'react-router-dom'
import NavBarTop from '../../Layout/Components/NavBar'
import {Accordion, Alert, Badge, Button, Card, Col, Container, Form, Row, Spinner} from 'react-bootstrap'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DefaultAvatar from '../../Layout/Assets/user.svg'
import ScrollableTabsButtonAuto from '../../Layout/Components/Tabs'
import swal from 'sweetalert'

export default class Property extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectValue: '',
      inputFileValue: '',
      user: {
        token: '',
        email: '',
        avatar: '',
        role_id: '',
        name: ''
      },
      loading: true,
      data: {
        file_type: '',
        document: ''
      },
      property: {
        id: '',
        arriendo: '',
        bodega: '',
        depto: '',
        estacionamiento: '',
        nombre: '',
        precio: '',
        usuario_id: ''
      },
      rents: [],
      files: {},
      alert: '',
      tabValue: 0
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount() {
    const property = await this.getSingleProperty()
    const rents = await this.getPropertyRents(property.breal_id)
    const files = await this.getFiles(this.props.match.params.id)

    await this.getUser()

    this.setState({
      loading: false,
      property,
      rents,
      files
    })
  }

  async refreshFileList() {
    const files = await this.getFiles(this.props.match.params.id)
    this.setState({
      files
    })
  }

  async getSingleProperty() {
    return await Api.getSingleProperty({id: this.props.match.params.id})
  }

  async getPropertyRents(breal_id) {
    let rents = await Api.getPropertyRents({breal_id: breal_id})
    return rents
  }

  async getFiles(property_id) {
    let contratoGestion = await Api.getPropertyFiles({property_id, file_type: 1})
    let contratoArriendo = await Api.getPropertyFiles({property_id, file_type: 2})
    let reparaciones = await Api.getPropertyFiles({property_id, file_type: 3})
    let facturas = await Api.getPropertyFiles({property_id, file_type: 4})
    let varios = await Api.getPropertyFiles({property_id, file_type: 5})
    return {contratoGestion, contratoArriendo, reparaciones, facturas, varios}
  }

  async getUser() {
    let user = Session.getInfoUser()
    this.setState({
      user
    })
  }

  onChange = (e) => {
    let {data} = this.state
    data.document = e.target.files[0]
    this.setState({...data, inputFileValue: e.target.value})
  }

  onFileTypeSelect = (e) => {
    let {data} = this.state
    data.file_type = e.target.value
    this.setState({...data, selectValue: data.file_type})
  }

  async onSubmit(e) {
    e.preventDefault()

    const {data, property} = this.state
    const request = new FormData()

    if (data.file_type === 1) {
      let properties = []
      const wrapper = document.createElement('div')
      wrapper.innerHTML = `Al cargar un nuevo contrato de gestión este se actualizará en todas las propiedades de: <strong>${property.client.nombre}</strong>`
      swal({
        title: `Carga masiva contrato de gestión`,
        content: wrapper,
        icon: 'info',
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Cancel',
            visible: true,
            closeModal: true
          },
          confirm: {
            text: 'Si, continuar',
            value: true,
            visible: true,
            className: '',
            closeModal: false,
            focus: false
          }
        }
      })
        .then(async (willConfirm) => {
          const buttonCancel = document.querySelector('.swal-button--cancel')
          buttonCancel.remove()

          if (willConfirm) {
            try {
              properties = await Api.getProperties({email: property.client.email})
              const uniquePropertiesArray = properties.reduce((items, item) => items.filter(i => i.id === item.id).length < 1 ? [...items, item] : items, [])
              const request = new FormData()
              request.append('document', data.document)
              request.append('file_type', data.file_type)
              const file = await Api.uploadFile(request)
              console.log('properties', uniquePropertiesArray)
              return Promise.all(uniquePropertiesArray.map(({data}, index) => {
                  return Api.insertFile({
                    documentUrl: file.data.documentUrl,
                    file_type: file.data.file_type,
                    property_id: uniquePropertiesArray[index].id
                  })
                }
              ))
            } catch (e) {
              console.error(e)
              throw new Error('close')
            }
          } else {
            throw new Error('close')
          }
        }).then(({error}) => {
        if (!error) {
          this.setState({data: {file_type: ''}, selectValue: '', inputFileValue: ''})
          this.refreshFileList()
          const wrapperSuccess = document.createElement('div')
          wrapperSuccess.innerHTML = `<h4>Propiedades actualizadas (${properties.length})</h4>`
          wrapperSuccess.innerHTML += `<ul>`
          wrapperSuccess.innerHTML += properties.reduce((accumulator, property) => accumulator + `<li>${property.condominio} ${parseInt(property.nro_depto) || ''}</li>`, '')
          wrapperSuccess.innerHTML += `</ul>`
          swal('Contratos de gestion actualizados', {
            icon: 'success',
            content: wrapperSuccess
          })
        } else {
          throw new Error('Api error')
        }
      }).catch(err => {
        if (err.message === 'close') {
          swal.stopLoading()
          swal.close()
        } else if (err) {
          swal('Error', 'La acción solicitada no se ha completado', 'error')
        } else {
          swal.stopLoading()
          swal.close()
        }
      })
    } else {
      request.append('document', data.document)
      request.append('file_type', data.file_type)

      let response = await Api.uploadFile(request)
      let params = {
        documentUrl: response.data.documentUrl,
        file_type: response.data.file_type,
        property_id: this.state.property.id
      }

      const insertResponse = await Api.insertFile(params)

      if (insertResponse.message === 'File uploaded!') {
        this.setState({data: {file_type: ''}, selectValue: '', inputFileValue: ''})
        this.showAlert('Archivo cargado correctamente.', 'success')
        this.refreshFileList()
      } else {
        this.showAlert('Ha ocurrido un error.')
      }
    }
  }

  hideAlert = () => {
    this.setState({alert: false})
  }

  showAlert(text, variant = 'danger') {
    const getAlert = () => (
      <Alert
        variant={variant}
        onClose={() => this.hideAlert(false)}
        dismissible
      >
        <p>{text}</p>
      </Alert>
    )

    this.setState({
      alert: getAlert()
    })
  }

  handleChange(newValue) {
    this.setState({
      tabValue: newValue
    })
  }

  render() {
    let propertyInfo = this.state.property
    let {alert, files} = this.state

    return this.state.loading ? (
      <>
        <NavBarTop/>
        <Container className="rectangle">
          <Spinner animation="grow" variant="dark"/>
        </Container>
      </>
    ) : (
      <>
        <NavBarTop/>
        <div className="layout-main">
          <Container className="rectangle-transparent">
            <Row>
              <Link to="/home">
                <h6 className="h6-margin">Inicio</h6>
              </Link>{' '}
              <h6 className="h6-margin">/</h6>{' '}
              <Link to="/property">
                <h6 className="h6-margin">
                  Propiedad [{propertyInfo.nombre}]
                </h6>
              </Link>
            </Row>
          </Container>
          <Container className="rectangle">
            <Row>
              <Col>
                <img
                  className="img-fluid"
                  src={DefaultAvatar}
                  alt="avatar"
                />
              </Col>
              <Col>
                <h4>{this.state.user.name}</h4>
                <h6>Correo: {this.state.user.email}</h6>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </div>
        <Container className="rectangle margin-top-10">
          <Accordion>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                >
                  {'Informacion ' +
                  propertyInfo.nombre_condominio}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row>
                    <Col>
                      <h4>Id Intranet</h4>{' '}
                      {propertyInfo.id}
                    </Col>
                    <div className="divider"></div>
                    <Col>
                      <h4>Código</h4>
                      {propertyInfo.nombre}{' '}
                    </Col>
                    <div className="divider"></div>
                    <Col>
                      <h4>Estacionamiento</h4>
                      <h6>
                        Rol:{' '}
                        {returnTextByNullField(
                          propertyInfo.rol_estacionamiento,
                          'E'
                        )}
                      </h6>
                      <h6>
                        Numero:{' '}
                        {returnTextByNullField(
                          propertyInfo.nro_estacionamiento,
                          'E'
                        )}
                      </h6>
                    </Col>
                  </Row>
                  <div className="divider-h"></div>
                  <Row>
                    <Col>
                      <h4>Bodega</h4>
                      <h6>
                        Rol:{' '}
                        {returnTextByNullField(
                          propertyInfo.rol_bodega,
                          'B'
                        )}
                      </h6>
                      <h6>
                        Numero:{' '}
                        {returnTextByNullField(
                          propertyInfo.nro_bodega,
                          'B'
                        )}
                      </h6>
                    </Col>
                    <div className="divider"></div>
                    <Col lg>
                      <h4>
                        Estado:<br/>
                        {returnBadgeByState(
                          propertyInfo.arriendo
                        )}
                      </h4>
                    </Col>
                    <Col lg>
                      <h4>
                        Cliente
                      </h4>
                      <h6>
                        {propertyInfo.client.nombre} - {propertyInfo.client.email}
                      </h6>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="1"
                >
                  Arriendos
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {returnRentTable(this.state.rents)}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="2"
                >
                  Archivos
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <Row>
                    <Col>
                      <ScrollableTabsButtonAuto
                        files={this.state.files}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="3"
                >
                  Subir Archivos
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <Row className={'justify-content-center'}>
                    <div
                      className="col-12 col-md-6"
                      onClick={this.hideAlert}
                    >
                      {alert}
                    </div>
                  </Row>
                  <Form>
                    <InputLabel id="demo-simple-select-label">Tipo archivo</InputLabel>
                    <Select
                      value={this.state.selectValue}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={this.onFileTypeSelect}
                    >
                      <MenuItem value={1}>Contrato de gestión</MenuItem>
                      <MenuItem value={2}>Contrato de arriendo</MenuItem>
                      <MenuItem value={3}>Resparaciones y Post Ventas</MenuItem>
                      <MenuItem value={4}>Liquidaciones</MenuItem>
                      <MenuItem value={5}>Varios</MenuItem>
                    </Select>
                    <hr/>
                    <InputBase
                      value={this.state.inputFileValue}
                      name="document"
                      placeholder="Selecciones un archivo"
                      type="file"
                      onChange={this.onChange}/>
                    <hr/>
                    <Button
                      variant="outline-primary"
                      type="submit"
                      onClick={this.onSubmit}
                    >
                      Subir Archivo
                    </Button>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </>
    )
  }
}

// Text: Text from SQL, Type: Estacionamiento, Bodega, etc...
function returnTextByNullField(text, type) {
  var output = ''
  if (text === 'null' || text === 'undefined') {
    output = type === 'B' ? 'S/B' : 'S/E'
  } else output = text
  return output
}

function returnBadgeByState(text) {
  if (text === 'Disponible') {
    return <Badge variant="primary">{text}</Badge>
  } else {
    return <Badge variant="success">{text}</Badge>
  }
}

function returnRentTable(rents) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead className="bold-text">
          <TableRow>
            <TableCell>Activo</TableCell>
            <TableCell>Monto Garantía</TableCell>
            <TableCell>Valor Arriendo</TableCell>
            <TableCell>Fecha Inicio</TableCell>
            <TableCell>Fecha Termino</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rents.map((row) => (
            <TableRow key={row.id_propiedad + '-' + row.id}>
              <TableCell component="th" scope="row">
                {row.activo === '1' ? 'Si' : 'No'}
              </TableCell>
              <TableCell>
                {'$' +
                new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'CLP'
                }).format(row.monto_garantia)}
              </TableCell>
              <TableCell>
                {'$' +
                new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'CLP'
                }).format(row.valor)}{' '}
              </TableCell>
              <TableCell>
                {row.inicio_contrato.split('T')[0]}
              </TableCell>
              <TableCell>
                {row.termino_contrato.split('T')[0]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
